<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:38:20
 * @LastEditTime: 2023-07-04 11:18
 * @Descripttion: 【作业考试-考试】批阅
-->
<style lang="scss" scoped>
.exam-read {
  @include innerPage;
  @include pageHead(-42px);

  .page-inner {
    width: 100%;
    height: calc(100% - 194px);
    margin-top: 32px;
    @include flexBox(space-between, flex-start);

    .students,
    .grade-box {
      width: calc((100% - 1000px) / 2);
      height: 100%;
      border-radius: 10px;
      background: #fff;
    }

    .students {
      background: #f0f3fa;
      box-sizing: border-box;
      padding: 6px 10px 10px;

      .statistics-read {
        width: 100%;
        height: 122px;
        border-bottom: 1px solid #e2e4ea;
        position: relative;
        @include flexBox;

        p {
          width: 50%;
          height: 100%;
          line-height: 36px;
          font-size: 16px;
          color: #2a2a2a;
          flex-direction: column;
          @include flexBox(center);

          b {
            font-size: 22px;
            color: #6340c8;
          }

          span {
            margin-top: -2px;
          }

          &:first-child b {
            color: #28ca96;
          }
        }

        &:after {
          content: "";
          width: 1px;
          height: 14px;
          background: #7d7d7d;
          position: absolute;
          bottom: 37px;
          left: 50%;
          transform-origin: 0 0;
          transform: translateX(-50%) scaleX(0.5);
        }
      }

      ul {
        width: 100%;
        height: calc(100% - 162px);
        margin-top: 30px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 0 30px;

        .student {
          width: 100%;
          height: 80px;
          box-sizing: border-box;
          padding-top: 6px;
          padding-right: 24px;
          margin-bottom: 30px;
          cursor: pointer;
          @include flexBox;

          .el-avatar {
            border: 2px solid #c8c3e3;
            border-radius: 50%;
          }

          .read-info {
            width: calc(100% - 72px);
            height: 100%;
            margin-left: 10px;
            flex-direction: column;
            @include flexBox(space-around, flex-start);

            .student-info {
              width: 100%;
              height: 36px;
              margin-top: -4px;
              @include flexBox;

              span:not(.name) {
                flex-shrink: 0;
              }

              .name {
                margin-right: auto;
                font-size: 16px;
                color: #2b2b2b;
                flex-grow: 1;
              }

              .pf_bold {
                font-size: 20px;
                color: #ee4f65;
              }
            }

            .read-result {
              line-height: 20px;
              color: #6b6b6b;
            }
          }

          .will-read {
            font-size: 14px;
            color: #656565;
            @include flexBox;

            .iconfont {
              font-size: 24px;
              color: #c8c3e3;
              margin-right: 6px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.readed {
            padding-right: 0;
            align-items: flex-start;
          }

          &.reading {
            .will-read {
              color: #ee4f65;

              .iconfont {
                color: #ee4f65;
              }
            }

            .el-avatar {
              border-color: #ee4f65;
            }
          }

          &:not(.readed):not(.reading):hover {
            transform: translateX(20px);

            .will-read {
              color: #ee4f65;

              .iconfont {
                color: #ee4f65;
              }
            }
          }
        }

        .no-data--empty {
          top: 30%;

          img {
            width: 160px;
          }
        }
      }
    }

    .grade-box {
      box-sizing: border-box;
      padding: 22px 6px 0;

      .form-wrapper {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        box-sizing: border-box;
        padding: 0 18px;
        overflow: hidden;
        overflow-y: auto;

        &.has-btns {
          height: calc(100% - 90px);
        }

        ::v-deep {
          .el-form {
            width: 100%;

            &-item {
              width: 100%;
              padding-bottom: 28px;
              position: relative;
              margin-bottom: 18px;
              border-bottom: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);

              &.evaluation-type {
                position: relative;
              }

              .el-input__inner,
              .el-textarea__inner {
                background: #e9e9f4;
                padding: 0 20px;
              }

              .el-textarea__inner {
                min-height: 206px !important;
                padding: 24px 20px 14px;
              }

              &__label {
                font-family: "MicrosoftYaHei Bold";
                font-size: 16px;
                line-height: 36px;
                color: #303030;
              }

              &:first-child {
                .el-input__inner {
                  font-size: 24px;
                  color: #303030;
                  height: 52px;
                  line-height: 52px;
                }
              }

              &:nth-last-child(2),
              &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
              }

              .el-form-item {
                border-bottom: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
                padding-bottom: 28px;
                margin-bottom: 18px;

                &:last-child {
                  padding-bottom: 0;
                  margin-bottom: 0;
                  border-bottom: none;
                }
              }
            }
          }

          .label-right {
            position: absolute;
            top: 150px;
            right: 0;
            padding-right: 0;
            z-index: 2;

            &:nth-child(1) {
              top: 0;
            }

            .el-radio__label {
              color: #404040;
            }

            &::after {
              content: "\e63a";
              font-family: "iconfont";
              font-size: 12px;
              color: #fff;
              position: absolute;
              top: 50%;
              left: 3px;
              transform: translateY(-50%) scaleY(0);
              @include defaultAni;
            }

            &.is-checked::after {
              transform: translateY(-50%) scaleY(1);
            }
          }

          .el-radio-group {
            width: calc(100% + 15px); 
          }

          .el-radio-button {
            width: 52px;
            height: 52px;
            border-radius: 10px;
            border: none;
            margin:0 14px 14px 0;

            &__inner {
              width: 52px;
              height: 52px;
              line-height: 52px;
              font-size: 16px;
              text-align: center;
              padding: 0;
              border-radius: 10px;
              border: none;
              background: #e9e9f4;
            }

            &.is-active .el-radio-button__inner {
              background: #6340c8;
            }
          }

          .remark-group {
            width: calc(100% + 10px);
            margin-top: 26px;
            flex-wrap: wrap;
            position: relative;
            @include flexBox;

            .remark-item {
              max-width: 100%;
              height: 36px;
              margin: 0 10px 10px 0;
              box-sizing: border-box;
              padding: 0 16px;
              line-height: 36px;
              text-align: center;
              color: #6340c8;
              background: rgba($color: #6340c8, $alpha: 0.14);
              border-radius: 10px;
            }
          }

          .el-checkbox {
            &__inner {
              width: 20px;
              height: 20px;
            }

            &__label {
              color: #191919;
              font-size: 16px;
              margin-left: 6px;
            }
          }
        }

        .more-remark {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #6c4ecb;
          margin-bottom: 6px;
          cursor: pointer;

          &:hover {
            background: #8971d5;
          }

          .iconfont {
            font-size: 18px;
            text-align: center;
            line-height: 24px;
            color: #fff;
          }
        }

        .textarea {
          margin-top: 20px;
        }
      }

      .foot-btns {
        width: calc(100% - 36px);
        height: 80px;
        margin: 0 auto;
        position: relative;
        border-top: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
        @include flexBox(space-between);

        .el-button {
          flex-grow: 1;
          font-size: 16px;
          position: relative;

          &::after {
            content: "";
            width: 1px;
            height: 20px;
            background: #b5b5b5;
            position: absolute;
            top: 50%;
            left: 0;
            transform-origin: 0 0;
            transform: translateY(-50%) scaleX(0.5);
          }

          &:first-child::after {
            background: transparent;
          }
        }
      }
    }

    .read-box {
      width: 960px;
      height: 100%;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;

      .head {
        width: calc(100% - 92px);
        height: 124px;
        margin: 0 auto;
        border-bottom: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
        box-sizing: border-box;
        padding: 38px 38px 0;

        h3 {
          line-height: 36px;
          text-align: center;
        }

        .data-info {
          height: 36px;
          margin-top: 4px;
          color: #676767;
          @include flexBox;

          p {
            &:first-child {
              flex-grow: 1;
              margin-right: auto;
            }

            &:last-child {
              flex-shrink: 0;
              margin-left: 20px;
            }
          }
        }
      }

      .read-exercise {
        height: calc(100% - 124px);
        box-sizing: border-box;
        padding-right: 10px;
        padding-bottom: 10px;

        .imgs {
          height: 62px;
          margin-top: 20px;
          box-sizing: border-box;
          padding: 0 36px 0 46px;
          @include flexBox;

          .el-image {
            width: 62px;
            height: 62px;
          }
        }

        &-box {
          width: 100%;
          height: calc(100% - 20px);
          box-sizing: border-box;
          padding: 0 36px 0 46px;
          overflow: hidden;
          overflow-y: auto;
          margin-top: 20px;

          &.picture {
            height: calc(100% - 102px);
          }
        }
      }

      .read-offline {
        width: 100%;
        height: calc(100% - 124px);

        .upload-wrapper {
          width: 100%;
          height: 100%;

          &.has-btn {
            height: calc(100% - 108px);
          }
        }

        .upload-box {
          width: 100%;
          height: 100%;

          ::v-deep .el-upload {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 20px 0;

            &--text {
              height: 100%;

              .el-upload-dragger {
                width: 868px;
                margin: 0 auto;
                border-radius: 0;
                background: transparent;
                border: none;
                @include flexBox(center);

                &:hover {
                  background: #f6f7fa;
                }
              }
            }
          }
        }

        .upload-files {
          width: calc(100% - 10px);
          height: calc(100% - 20px);
          margin: 10px 10px 10px 0;
          overflow: hidden;
          overflow-y: auto;
          cursor: default;

          li {
            width: 868px;
            margin: 0 auto 0 46px;
            margin-bottom: 20px;

            .el-image {
              width: 100%;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .foot-btns {
          width: 868px;
          height: 108px;
          box-sizing: border-box;
          margin: 0 auto;
          border-top: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
          @include flexBox(flex-end);
        }
      }
    }
  }
}

.question {
  width: 868px;
  box-sizing: border-box;
  padding: 30px 0;
  border-bottom: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
  @include flexBox;
  align-items: baseline;

  &-group {
    padding-bottom: 20px;

    h5.bold {
      color: #4c4c4c;
    }

    &:last-child {
      .question:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &-wrapper {
    box-sizing: border-box;
    padding-left: 20px;
  }

  &--num,
  &--type {
    flex-shrink: 0;
    line-height: 24px;
    flex-shrink: 0;
  }

  &--num {
    color: #1f1f1f;
    font-size: 16px;
  }

  &--type {
    min-width: 56px;
    height: 24px;
    background: #6340c8;
    border-radius: 12px 0px 12px 12px;
    text-align: center;
    color: #fff;
    margin: 0 14px 0 4px;
    box-sizing: border-box;
    padding: 0 4px;
  }

  &--data {
    width: calc(100% - 100px);

    h5 {
      line-height: 24px;
      color: #1f1f1f;
      span{
        color: #999999;
      }
    }

    .imgs-group {
      margin-top: 10px;
      @include flexBox;

      .el-image {
        width: 130px;
        height: 78px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }

    .options {
      margin-top: 14px;

      li {
        line-height: 30px;
        color: #666;

        .option--text {
          align-items: flex-start;
          display: inline-flex;
        }

        .imgs-group {
          margin-top: -4px;
          margin-bottom: 2px;
          box-sizing: border-box;
          padding-left: 52px;
        }

        &:last-child {
          .option--text {
            margin-bottom: 0;
          }

          .imgs-group {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  ::v-deep .el-radio {
    &__input {
      &:not(.is-disabled) {
        .el-radio__inner {
          &:hover {
            border-color: #2ac293;
          }
        }
      }

      &.is-checked {
        .el-radio__inner {
          border-color: #2ac293;
          background: #2ac293;
        }

        &+.el-radio__label {
          color: #2ac293;
        }
      }

      &__original:disabled {
        cursor: pointer;
      }
    }

    &:nth-child(2) {
      .el-radio__input:not(.is-disabled) {
        .el-radio__inner {
          &:hover {
            border-color: #f66478;
          }
        }

        &.is-checked {
          .el-radio__inner {
            border-color: #f66478;
            background: #f66478;
          }

          &+.el-radio__label {
            color: #f66478;
          }
        }
      }
    }
  }
}

.compose {
  &-wrapper {
    width: calc(100% + 70px);
    margin-left: -70px;
    margin-top: 18px;

    h5 {
      height: 38px;
      @include flexBox;

      span.bold {
        display: inline-block;
        flex-grow: 1;
        margin-right: 20px;
      }
    }

    .subtopic {
      margin-top: 20px;

      &-item {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 30px;
        background: rgba($color: #eef0f8, $alpha: 0.4);
        border-radius: 10px;
        color: #666;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &--title {
        font-size: 16px;
        line-height: 24px;
        span{
          color:#999999;
        }
      }

      &--options {
        box-sizing: border-box;
        padding: 0 40px;
        margin-top: 16px;

        .option {
          line-height: 30px;

          &--text {
            margin-bottom: 16px;
            align-items: flex-start;
            display: inline-flex;
          }

          .imgs-group {
            margin-top: -4px;
            margin-bottom: 2px;
            box-sizing: border-box;
            padding-left: 20px;
          }

          &:last-child {
            .option--text {
              margin-bottom: 0;
            }

            .imgs-group {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.answer {
  line-height: 24px;
  font-size: 14px;
  margin: 20px 0;

  &-judgment {
    @include flexBox(space-between);

    .score-ipt {
      height: 46px;
      font-size: 16px;
      color: #303030;
      opacity: 0;
      transform: scale(0);
      @include flexBox;

      input {
        width: 46px;
        height: 46px;
        background-color: #e9e9f4;
        border-radius: 8px;
        border: 1px solid #d7d7d7;
        text-align: center;
        padding: 0 4px;
        outline: none;
        margin: 0 10px;
        box-sizing: border-box;
        font-size: 24px;

        &:active,
        &:focus {
          border-color: #6c4ecb;
        }

        &:read-only {
          background: #e7e7e7;
          cursor: default;

          &:focus {
            border-color: transparent;
          }
        }
      }

      &.show {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
.stuanswer_image{
  width: 100px;
  height:150px;
  margin: 20px 0;
  border: 1px solid #DDDDDD;
  .el-image{
    width: 100%;
    height: 100%;
  }
}
.right-answer {
  width: 100%;
  min-height: 52px;
  background: #f0f3fa;
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 30px;
  font-size: 16px;
  color: #393737;
  @include flexBox(space-between, center);

  span {
    @include flexBox;

    &:last-child {
      color: #2ac293;
    }
  }

  .iconfont {
    font-size: 20px;
    color: #ee4f65;
  }
}

.radio {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f1f1f1;
  border: solid 1px #dcdcdc;
  flex-shrink: 0;
  margin: 5px 14px 5px 0;
}

.wrong {
  .radio {
    border-color: #f66478;
    background: #f66478;
  }
}

.correct {
  .radio {
    border-color: #2ac293;
    background: #2ac293;
  }
}
.selectimg{
	max-width: 100%;
	margin: 20px 0 0;
	display: block;
}

.imgs-group .selectimg{
  margin-top: 0;
}
.office_form{
  margin-bottom: 50px;
  h3{
      text-align: center;
      margin: 30px 0;
  }
  .office{
      margin:0 auto;
      width: 100%;
  }
}
</style>

<template>
  <section class="exam-read">
    <div class="page-head">
      <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
      <div class="head-wrapper">
        <breadcrumb />
        <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
      </div>
    </div>
    <div class="page-inner">
      <div class="students">
        <div class="statistics-read">
          <p>
            <b class="pf_bold">{{ readCount.teexa_reviewnum }}</b>
            <span>已批阅（份）</span>
          </p>
          <p>
            <b class="pf_bold">{{ readCount.teexa_sureread }}</b>
            <span>未批阅（份）</span>
          </p>
        </div>
        <ul :class="{ 'no-data': $isEmpty(students) }">
          <li class="student" v-for="(stu, stu_index) in students" :key="stu.stexa_id" :class="{
            readed: stu.stexa_status == 40,
            reading: currentReadIndex == stu_index,
          }" @click="changeStudent(stu_index)">
            <el-avatar fit="cover" :size="62" :src="formatfile(stu.stuser_image)" class="avatar">
              <img src="@assets/images/empty_avatar.png" />
            </el-avatar>
            <div class="read-info">
              <!-- 已批阅 -->
              <template v-if="stu.stexa_status == 40">
                <p class="student-info">
                  <span class="name line-text--1st"
                    :title="stu.stuser_name && stu.stuser_name.length > 3 && stu.stuser_name || ''">
                    {{ stu.stuser_name }}
                  </span>
                  <span>评分：</span>
                  <span class="pf_bold">
                    {{ stu.stexa_show == 20 && stu.stexa_grade || stu.stexa_show == 10 && stu.stexa_score }}
                  </span>
                </p>
                <template v-if="stu.error_mark == 1">
                  <p class="read-result">错题标记：已完成</p>
                  <p class="read-result">错题数：{{ stu.error_count }}题</p>
                </template>
              </template>
              <!-- 未批阅 -->
              <template v-if="stu.stexa_status != 40">
                <p class="student-info">
                  <span class="name line-text--1st"
                    :title="stu.stuser_name && stu.stuser_name.length > 3 && stu.stuser_name || ''">
                    {{ stu.stuser_name }}
                  </span>
                  <span class="will-read">
                    <i class="iconfont">&#xe61e;</i>待批阅
                  </span>
                </p>
              </template>
            </div>
          </li>
          <li class="no-data--empty" v-if="$isEmpty(students)">
            <img src="@assets/images/no-data3.png" alt="">
            <p>暂无学生数据哦~</p>
          </li>
        </ul>
      </div>
      <div class="read-box" :class="{ 'no-data': $isEmpty(currentReadExam) }">
        <template v-if="!$isEmpty(currentReadExam)">
          <div class="head">
            <h3 class="bold line-text--1st">{{ currentReadExam.tepap_title }}</h3>
            <div class="data-info">
              <p class="line-text--1st">考试规则：{{ currentReadExam.tepap_rule }}</p>
              <p>交卷时间：{{ currentReadExam.stexa_endtime | formatTime('YYYY-MM-DD HH:mm') }}</p>
            </div>
          </div>
          <div class="read-exercise" v-if="!isOnlineExam">
            <ul class="imgs" v-if="!$isEmpty(currentReadExam.stexa_images)">
              <li v-for="(item, index) in currentReadExam.stexa_images" :key="index">
                <!-- <img class="selectimg" v-if="formatfile(item)" :src="formatfile(item)" alt=""> -->
                <el-image fit="cover" :src="formatfile(item)" :preview-src-list="[formatfile(item)]" />
              </li>
            </ul>
            <div class="read-exercise-box" :class="{ picture: !$isEmpty(currentReadExam.stexa_images) }" ref="readExerciseBox">
              <div class="office_form" v-if="currentReadExam.stexa_att_paper_url">
                  <h3>试卷</h3>
                  <!-- pdf展示数据--试卷 -->
                  <div class="office">
                      <vueOffice v-model="currentReadExam.stexa_att_paper_url" type="pdf"></vueOffice>
                  </div>
              </div>
              <div class="office_form" v-if="currentReadExam.stexa_att_analysis_url">
                  <h3>解析</h3>
                  <!-- pdf展示数据--解析 -->
                  <div class="office">
                      <vueOffice v-model="currentReadExam.stexa_att_analysis_url" type="pdf"></vueOffice>
                  </div>
              </div>
              <div class="question-group" v-for="qg in currentReadExam.question_data" :key="qg.stexa_pap_top_id">
                <h5 class="bold">{{ qg.stexa_pap_top_title }}（{{ qg.stexa_pap_top_sumscore }}分）</h5>
                <ul class="question-wrapper">
                  <li class="question" :class="{ compose: item.syque_typ_id == 14 }"
                    v-for="(item, que_index) in qg.question" :key="item.stexa_que_id">
                    <span class="question--num pf_bold">
                      {{
                        (que_index + 1 > 9 && que_index + 1) || `0${que_index + 1}`
                      }}.
                    </span>
                    <span class="question--type">{{ item.syque_typ_name }}</span>
                    <div class="question--data">
                      <h5 class="bold"><em class="richinline" v-html="item.stexa_que_title"></em> <span >（{{ item.stexa_que_score }}分）</span> </h5>
                      <div class="imgs-group" v-if="item.stexa_que_title_image">
                        <img class="selectimg" v-if="formatfile(item.stexa_que_title_image)" :src="formatfile(item.stexa_que_title_image)" alt="">
                        <!-- <el-image fit="cover" :src="formatfile(item.stexa_que_title_image)" :preview-src-list="[formatfile(item.stexa_que_title_image)]" /> -->
                      </div>
                      <!-- 组合题 -->
                      <template v-if="item.syque_typ_id == 14">
                        <div class="compose-wrapper">
                          <ol class="subtopic">
                            <li class="subtopic-item" v-for="(s_item, s_index) in item.question_item"
                              :key="s_item.stexa_que_id">
                              <p class="subtopic--title">
                                （{{ s_index + 1 }}）【{{ s_item.syque_typ_name }}】<em class="richinline" v-html="s_item.stexa_que_title"></em> <span >（{{ s_item.stexa_que_score }}分）</span> 
                              </p>
                              <div class="imgs-group" v-if="s_item.stexa_que_title_image">
                                <img class="selectimg" v-if="formatfile(s_item.stexa_que_title_image)" :src="formatfile(s_item.stexa_que_title_image)" alt="">
                                <!-- <el-image fit="cover" :src="formatfile(s_item.stexa_que_title_image)" :preview-src-list="[ formatfile(s_item.stexa_que_title_image), ]" /> -->
                              </div>
                              <!-- 选择题选项 -->
                              <template v-if="isChoice(s_item.syque_typ_id)">
                                <dl class="subtopic--options">
                                  <dt class="option" v-for="o in s_item.question_child_item" :key="o.stexa_que_ite_id"
                                    :class="{
                                      wrong: o.is_angser == 1 && o.stexa_que_ite_isanswer == 20,
                                      correct: o.is_angser == 1 && o.stexa_que_ite_isanswer == 10,
                                    }">
                                    <span class="option--text">
                                      <i class="radio"></i>
                                      {{ o.stexa_que_ite_code }}.
                                      <span class="richinline" v-html="o.stexa_que_ite_title"></span>
                                    </span>
                                    <div class="imgs-group">
                                      <div class="imgs-group" v-if="o.stexa_que_ite_image">
                                        <img class="selectimg" v-if="formatfile(o.stexa_que_ite_image)" :src="formatfile(o.stexa_que_ite_image)" alt="">
                                        <!-- <el-image fit="cover" :src="formatfile(o.stexa_que_ite_image)" :preview-src-list="[
                                          formatfile(o.stexa_que_ite_image),
                                        ]" /> -->
                                      </div>
                                    </div>
                                  </dt>
                                </dl>
                                <div class="right-answer" v-if="s_item.stexa_que_isstustatus != 10">
                                  <span>
                                    回答错误：
                                    <i class="iconfont">&#xe63f;</i>
                                  </span>
                                  <span>正确答案：<span class="richinline" v-html="s_item.stexa_que_succ_answer"></span></span>
                                </div>
                              </template>
                              <!-- 非选择题 -->
                              <template v-if="!isChoice(s_item.syque_typ_id)">
                                <p class="answer" v-if="!$isEmpty(s_item.stexa_que_stuanswer)">
                                  作答内容：<span class="richinline" v-html="s_item.stexa_que_stuanswer.join(',')"></span>
                                </p>
                                <!-- 手写板图片 -->
                                <div class="stuanswer_image" v-if="s_item.stexa_que_stuanswer_image">
                                  <el-image class="el-image" :src="formatfile(s_item.stexa_que_stuanswer_image)" fit="cover" :preview-src-list="[formatfile(s_item.stexa_que_stuanswer_image)]"/>
                                </div>
                                <div class="answer-judgment">
                                  <el-radio-group v-model="s_item.stexa_que_isstustatus" :disabled="isReaded"
                                    @input="s_item.stexa_que_teascore = 0; determineRight(s_item)">
                                    <el-radio :label="10">正确</el-radio>
                                    <el-radio :label="20">错误</el-radio>
                                  </el-radio-group>
                                  <div class="score-ipt" :class="{ show: s_item.stexa_que_isstustatus }">
                                    <input type="text" v-model="s_item.stexa_que_teascore"
                                      :style="{ width: `${(s_item.stexa_que_teascore + '').length > 2 && (s_item.stexa_que_teascore + '').length * 20 || 46}px` }"
                                      :readonly="isReaded" maxlength="4"
                                      @input="s_item.stexa_que_teascore = iptInit(s_item.stexa_que_teascore, s_item.stexa_que_score)"
                                      @blur=" s_item.stexa_que_teascore = iptInitBlur(s_item.stexa_que_teascore); determineRight(s_item)" />
                                    <span>分</span>
                                  </div>
                                </div>
                              </template>
                            </li>
                          </ol>
                        </div>
                      </template>
                      <!-- 其他题型 -->
                      <template v-if="item.syque_typ_id != 14">
                        <!-- 选择题选项 -->
                        <template v-if="isChoice(item.syque_typ_id)">
                          <ul class="options">
                            <li :class="{
                              wrong: o.is_angser == 1 && item.stexa_que_isstustatus == 20,
                              correct: o.is_angser == 1 && item.stexa_que_isstustatus == 10,
                            }" v-for="o in item.question_item" :key="o.stexa_que_ite_id">
                              <span class="option--text">
                                <i class="radio"></i>
                                {{ o.stexa_que_ite_code }}.
                                <span class="richinline" v-html="o.stexa_que_ite_title"></span>
                              </span>
                              <div class="imgs-group" v-if="o.stexa_que_ite_image">
                                <img class="selectimg" v-if="formatfile(o.stexa_que_ite_image)" :src="formatfile(o.stexa_que_ite_image)" alt="">
                                <!-- <el-image fit="cover" :src="formatfile(o.stexa_que_ite_image)" :preview-src-list="[
                                  formatfile(o.stexa_que_ite_image),
                                ]" /> -->
                              </div>
                            </li>
                          </ul>
                          <div class="right-answer" v-if="item.stexa_que_isstustatus != 10">
                            <span>
                              回答错误：
                              <i class="iconfont">&#xe63f;</i>
                            </span>
                            <span>正确答案：<span class="richinline" v-html="item.stexa_que_succ_answer"></span></span>
                          </div>
                        </template>
                        <!-- 非选择题 -->
                        <template v-if="!isChoice(item.syque_typ_id)">
                          <p class="answer" v-if="!$isEmpty(item.stexa_que_stuanswer)">
                            作答内容：<span class="richinline" v-html="item.stexa_que_stuanswer.join(',')"></span>
                          </p>
                          <!-- 手写板图片 -->
                          <div class="stuanswer_image" v-if="item.stexa_que_stuanswer_image">
                            <el-image class="el-image" :src="formatfile(item.stexa_que_stuanswer_image)" fit="cover" :preview-src-list="[formatfile(item.stexa_que_stuanswer_image)]"/>
                          </div>
                          <div class="answer-judgment">
                            <el-radio-group v-model="item.stexa_que_isstustatus" :disabled="isReaded"
                              @input="item.stexa_que_teascore = 0; determineRight(item)">
                              <el-radio :label="10">正确</el-radio>
                              <el-radio :label="20">错误</el-radio>
                            </el-radio-group>
                            <div class="score-ipt" :class="{ show: item.stexa_que_isstustatus }">
                              <input type="text" v-model="item.stexa_que_teascore"
                                :style="{ width: `${(item.stexa_que_teascore + '').length > 2 && (item.stexa_que_teascore + '').length * 20 || 46}px` }"
                                :readonly="isReaded" maxlength="4"
                                @input="item.stexa_que_teascore = iptInit(item.stexa_que_teascore, item.stexa_que_score)"
                                @blur="item.stexa_que_teascore = iptInitBlur(item.stexa_que_teascore); determineRight(item)" />
                              <span>分</span>
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="read-offline" v-if="isOnlineExam">
            <div class="upload-wrapper" :class="{ 'has-btn': !$isEmpty(examPaperImgs) && !isReaded }">
              <el-upload class="upload-box" ref="upload" :action="$upload.imgAction" :headers="$upload.header"
                :accept="$upload.imgAccept" :before-upload="$beforImgUpload" 
                :show-file-list="examPaperImgs.length==0"
                :file-list="examPaperImgs" drag multiple
                v-show="!examPaperImgs.length" :on-success="uploadSuccess">
                <div>
                  <p class="iconfont">&#xe603;</p>
                  <p class="tips">
                    拖拽或
                    <span>点击上传</span>
                  </p>
                </div>
              </el-upload>
              <ul class="upload-files" v-if="examPaperImgs.length">
                <li v-for="(item, index) in examPaperImgs" :key="index">
                  <el-image fit="cover" :src="formatfile(item)" />
                </li>
              </ul>
            </div>
            <div class="foot-btns" v-if="!$isEmpty(examPaperImgs) && !isReaded">
              <el-button type="custom_primary" size="small" @click="resetUpload">重新上传</el-button>
            </div>
          </div>
        </template>
        <div class="no-data--empty" v-if="$isEmpty(currentReadExam)">
          <img src="@assets/images/no-data3.png" alt="">
          <p>暂无考试数据哦~</p>
        </div>
      </div>
      <div class="grade-box">
        <div class="form-wrapper" :class="{ 'has-btns': !$isEmpty(students) }">
          <el-form ref="gradeForm" :model="gradeForm" label-width="100%" label-position="top" :rules="gradeRules">
            <el-form-item class="evaluation-type">
              <!-- 评分 -->
              <template v-if="!isReaded || (gradeForm.stexa_score + '')">
                <el-radio v-if="!isReaded" v-model="gradeForm.stexa_show" :label="10" class="label-right">优先展示</el-radio>
                <el-form-item label="评分" prop="stexa_score">
                  <el-input v-model.trim="gradeForm.stexa_score" maxlength="4" :readonly="isReaded"
                    @input="gradeForm.stexa_score = iptInit(gradeForm.stexa_score, currentReadExam.tepap_sumscore)"
                    @blur="gradeForm.stexa_score = iptInitBlur(gradeForm.stexa_score)" />
                </el-form-item>
              </template>
              <!-- 评级 -->
              <template v-if="!isReaded || (gradeForm.stexa_grade + '')">
                <el-radio v-if="!isReaded" v-model="gradeForm.stexa_show" :label="20" class="label-right">优先展示</el-radio>
                <el-form-item label="评级" prop="stexa_grade">
                  <el-radio-group v-model="gradeForm.stexa_grade" :disabled="isReaded">
                    <el-radio-button label="A" value="A" />
                    <el-radio-button label="B" value="B" />
                    <el-radio-button label="C" value="C" />
                    <el-radio-button label="D" value="D" />
                  </el-radio-group>
                </el-form-item>
              </template>
            </el-form-item>
            <el-form-item label="评语" class="textarea">
              <el-input v-model.trim="gradeForm.stexa_comment" type="textarea" maxlength="200" placeholder="请输入"
                :readonly="isReaded" />
              <!-- 评语列表 -->
              <div class="remark-group" v-if="!isReaded">
                <p class="remark-item line-text--1st" v-for="item in remarks.slice(0, 3)" :key="item.plrem_id"
                  @click="chooseRemark(item)">
                  {{ item.plrem_title }}
                </p>
                <el-dropdown class="more-remark" placement="bottom-start" v-if="remarks.length > 3">
                  <p class="iconfont">&#xe8aa;</p>
                  <el-dropdown-menu slot="dropdown" style="width:250px">
                    <el-dropdown-item v-for="item in remarks.slice(3, remarks.length)" :key="item.plrem_id"
                      @click.native="chooseRemark(item)">
                      {{ item.plrem_title }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot-btns" v-if="!$isEmpty(students)">
          <el-button type="text" class="bold default" :disabled="currentReadIndex == 0"
            @click="changeStudent(currentReadIndex - 1)">上一份</el-button>
          <el-button type="text" class="bold default" :disabled="currentReadIndex == students.length - 1"
            @click="changeStudent(currentReadIndex + 1)">
            下一份
          </el-button>
          <el-button type="text" class="bold default" v-if="!isReaded" :disablde="btnload" v-loading="btnload"
            @click="submitReadResult">
            提交
          </el-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  $examStudents,
  $studentAnswerExam,
  $determineExamQues,
  $finishReadExam,
  $getRemarks
} from "@api/homeworkExam";
import { formatFile, iptFloat } from "@utils";
import vueOffice from "@comp/vueOffice"
export default {
  name: "he_examRead",
  components: {
      vueOffice
  },
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url)
      }
    },
    iptInit() {
      return function (val, max) {
        if (max && Number(val) > Number(max)) val = max + '';
        return iptFloat(val);
      }
    },
    iptInitBlur() {
      return function (val) {
        if (!val) return 0;
        if (val && val.split('.')[1] == '') return val.split('.')[0];
        return val;
      }
    },
    /** 判断是否是选择题 */
    isChoice() {
      const choiceTypeIds = [1, 2, 5];
      return function (val) {
        return choiceTypeIds.indexOf(val) != -1;
      };
    },
    /** 判断当前学生试卷是否已完成批阅 */
    isReaded() {
      return this.currentReadExam.stexa_status == 40;
    },
    /** 判断当前考试是否为线上考试 */
    isOnlineExam() {
      return this.currentReadExam.stexa_type == 20;
    },
  },
  data() {
    let validateScore = (rule, value, callback) => {
      if (this.gradeForm.stexa_show == 10 && !(value + "")) {
        callback(new Error("请填写考试评分"));
      } else if (this.gradeForm.sthom_wor_show == 10 && value < 0) {
        callback(new Error("请填写正确的考试评分"));
      } else {
        callback();
      }
    };
    let validateGrade = (rule, value, callback) => {
      if (this.gradeForm.stexa_show == 20 && !value) {
        callback(new Error("请填写考试评级"));
      } else {
        callback();
      }
    };
    return {
      grade: null,
      class: null,
      examId: null,
      readCount: {}, // 考试批阅信息
      students: [], // 学生信息（批阅）
      remarks: [], // 评语列表
      currentReadIndex: 0, // 当前批阅学生下标
      currentReadStu: {}, // 当前批阅学生信息
      currentReadExam: {
        // 试卷类型
        stexa_make_type:10,
        //pdf试题
        stexa_att_paper_url:"",
        //dpf解析
        stexa_att_analysis_url:"",
      }, // 当前批阅考试信息
      gradeForm: {
        stexa_show: 10,
        stexa_score: 0,
      },
      btnload: false,
      gradeRules: {
        stexa_score: [{ validator: validateScore, trigger: "blur" }],
        stexa_grade: [{ validator: validateGrade, trigger: "change" }],
      },
      examPaperImgs: [], // 线下考试试卷图片组
    };
  },
  created() {
    let { id, exam } = this.$route.params;
    id = id.split(",");
    this.grade = id[0];
    this.class = id[1];
    this.examId = exam;
    this.getExamStudents();
    this.getRemarks();
  },
  methods: {
    /** 获取参考学生列表 */
    async getExamStudents() {
      let { data } = await $examStudents(this.examId);
      this.students = data.student_data;
      this.readCount = data.count_data;
      this.currentReadStu = this.students[this.currentReadIndex];
      this.getReadInfo();
    },
    /** 获取评语列表 */
    async getRemarks() {
      let { data } = await $getRemarks(10);
      this.remarks = [...data];
    },
    /**
     * 选中评语
     * @param {object} data 评语数据 
     */
    chooseRemark(data) {
      this.gradeForm = {
        ...this.gradeForm,
        stexa_comment: `${this.gradeForm.stexa_comment || ''}${data.plrem_title || ''}`
      };
      this.$forceUpdate();
    },
    /** 切换学生试卷信息 */
    changeStudent(index) {
      this.currentReadIndex = index;
      this.currentReadStu = this.students[index];
      this.currentReadExam.stexa_att_paper_url=""
      this.currentReadExam.stexa_att_analysis_url=""
      this.examPaperImgs = [];
      this.gradeForm = {};
      this.$refs["gradeForm"].resetFields();
      this.gradeForm = {
        stexa_show: 10,
        stexa_score: 0,
      };
      this.getReadInfo();
    },
    /** 获取批阅学生试卷信息 */
    async getReadInfo() {
      let { stuser_id } = this.currentReadStu;
      let { data } = await $studentAnswerExam(this.examId, stuser_id);
      console.log((this.$isEmpty(data) && {}) || { ...data })
      this.currentReadExam = (this.$isEmpty(data) && {}) || { ...data };
      if (data.stexa_status == 40) {
        this.gradeForm = {
          stexa_score: data.stexa_score,
          stexa_grade: data.stexa_grade,
          stexa_show: data.stexa_show,
          stexa_comment: data.stexa_comment || "无",
        };
        if (this.isOnlineExam) {
          this.examPaperImgs = data.stexa_teaimages.split(",");
        }
      }
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$refs.readExerciseBox.scrollTop = 0;
      });
      // 计算总分
      this.getTotal()
    },
    /**
     * 提交非选择题批阅
     * @param {object} data 判定题目数据
     */
    async determineRight(data) {
      let { stexa_que_id, stexa_que_isstustatus, stexa_que_teascore } = data;
      stexa_que_teascore = Number(stexa_que_teascore);
      let params = { stexa_que_id, stexa_que_isstustatus, stexa_que_teascore };
      await $determineExamQues(params);
      this.getTotal()
    },
    /** 提交批阅结果 */
    submitReadResult() {
      if(this.btnload){return false}
      this.$refs.gradeForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            ...this.gradeForm,
            stexa_id: this.currentReadExam.stexa_id,
          };
          if (this.isOnlineExam && this.$isEmpty(this.examPaperImgs)) {
            this.btnload = false;
            return this.$message.error("请上传试卷图组");
          }
          else {
            params.stexa_teaimages = this.examPaperImgs.join(",");
          }
          let res = await $finishReadExam(params);
          setTimeout(() => {
            this.btnload = false;
          }, 3000);
          this.getExamStudents();
          if (res) return this.$message.success('提交成功')
        }
      });
    },
    /** 重新上传图片 */
    resetUpload() {
      this.examPaperImgs = [];
      this.$refs["upload"].$refs["upload-inner"].handleClick();
    },
    /**
     * 上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadSuccess({ data, msg }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      let imgs = this.examPaperImgs;
      imgs.push(data.address);
      this.examPaperImgs = [...imgs];
    },
    // 获取总分
    getTotal(){
      let total=0
      let currentReadExam=this.currentReadExam
      currentReadExam.question_data.map(qg=>{
        qg.question.map(item=>{
          // 非组合提
          if(item.syque_typ_id != 14){
            // 客观题直接通过对错加分
            if(item.syque_typ_id==1||item.syque_typ_id==2||item.syque_typ_id==5){
              if(item.stexa_que_isstustatus == 10||item.stexa_que_isstustatus == 30){
                total=(parseFloat(total) + parseFloat(item.stexa_que_teascore)).toFixed(1)
              }
            }else{
              // 主观题，直接看分数值是否有
              if(item.stexa_que_teascore){
                total=(parseFloat(total) + parseFloat(item.stexa_que_teascore)).toFixed(1)
              }
            }
          }else{
            item.question_item.map(s_item=>{
              if(s_item.syque_typ_id==1||s_item.syque_typ_id==2||s_item.syque_typ_id==5){
                if(s_item.stexa_que_isstustatus == 10||s_item.stexa_que_isstustatus == 30){
                  total=(parseFloat(total) + parseFloat(s_item.stexa_que_teascore)).toFixed(1)
                }
              }else{
                // 主观题，直接看分数值是否有
                if(s_item.stexa_que_teascore){
                  total=(parseFloat(total) + parseFloat(s_item.stexa_que_teascore)).toFixed(1)
                }
              }
            })
          }
        })
      })
      //没有批阅显示分数
      if(!this.isReaded){
        this.gradeForm.stexa_score=(total%1>0)?total:parseInt(total) 
      }
    },
  },
};
</script>
